.breadcrumbs-con {
    height: 100%;
    .breadcrumbs-col {
        display: flex;
    }
}

.breadcrumbs-con .breadcrumb {
    margin-bottom: 0 !important;
}

.breadcrumb ol {
    margin-bottom: 0 !important;
}

.breadcrumb-typo-bold {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: #000;
    font-family: "Onest";
}

.breadcrumb-max-width {
    max-width: 500px; // Default for extra-large screens
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1600px) {
    .breadcrumb-max-width {
        max-width: 450px; // Large desktops
    }
}

@media (max-width: 1400px) {
    .breadcrumb-max-width {
        max-width: 400px; // Medium-large desktops
    }
}

@media (max-width: 1200px) {
    .breadcrumb-max-width {
        max-width: 300px; // Standard desktops
    }
}

@media (max-width: 1024px) {
    .breadcrumb-max-width {
        max-width: 250px; // Large tablets, small laptops
    }
}

@media (max-width: 900px) {
    .breadcrumb-max-width {
        max-width: 200px; // Tablets and small laptops
    }
}

@media (max-width: 768px) {
    .breadcrumb-max-width {
        max-width: 150px; // Tablets in portrait mode
    }
}

@media (max-width: 600px) {
    .breadcrumb-max-width {
        max-width: 120px; // Large mobile devices, phablets
    }
}

@media (max-width: 480px) {
    .breadcrumb-max-width {
        max-width: 100px; // Standard mobile devices
    }
}

@media (max-width: 360px) {
    .breadcrumb-max-width {
        max-width: 80px; // Small mobile devices
    }
}

@media (max-width: 320px) {
    .breadcrumb-max-width {
        max-width: 70px; // Extra small mobile devices
    }
}
