//
// Google font - Poppins
//

// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

@font-face {
    font-family: "Onest";
    src: url(../../../fonts/plateTalk/Onest/Onest-VariableFont_wght.ttf) format("truetype");
}
