.body-search {
    .form-control {
        // Old Searchbar CSS
        // border: none;
        // height: 38px;
        // padding-left: 40px;
        // padding-right: 20px;
        // box-shadow: none;
        // border-radius: 4px;

        width: 300px;
        height: 40px;
        border: 1px solid #dce0e4;
        border-radius: 10px;
        padding-left: 20px;
        padding-right: 40px;
        outline: none;
        font-weight: 500;
    }
    span {
        position: absolute;
        z-index: 3;
        font-size: 25px;
        line-height: 38px;
        right: 10px;
        top: 0;
        color: #9da5b4;
    }
}
