.custom-vertical-dots {
    display: flex;
    position: absolute;
    top: 160px;
    right: 15px;
    height: 20rem;
    transform: translateY(-50%);
    max-height: 20rem;
    overflow-y: auto;
    background-color: #ffffff;
    border: 0.059rem;
}

.custom-vertical-dots li {
    list-style: none;
    margin: 0.403rem;
}

.custom-vertical-dots li button {
    border: none;
    background: transparent;
    padding: 0;
}

.custom-vertical-dots li img {
    width: 7.5rem;
    height: 4.375rem;
    border-radius: 0.588rem;
    cursor: pointer;
}

.custom-vertical-dots li.slick-active img {
    width: 7.5rem;
    height: 4.375rem;
}

.slider-container {
    width: 100%;
    position: relative; /* Make container relative to position background */

    .blurred-background {
        width: 80%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border-radius: 20px;
        transition: backdrop-filter 0.3s ease; /* Smooth transition for the blur effect */
    }

    .blurred-background.blur {
        backdrop-filter: blur(5px); /* Apply the blur effect */
        -webkit-backdrop-filter: blur(5px); /* For Safari support */
    }

    .slick-list {
        width: 80%;
        position: relative; /* Ensure slider content is above background */
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        z-index: 1;
        backdrop-filter: blur(10px); /* Apply the blur effect */
        -webkit-backdrop-filter: blur(5px); /* For Safari support */
    }

    .slider-image {
        width: 80%;
        height: 20rem;
        border-radius: 20px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        z-index: 2; /* Ensure image is above background */
        background: #b6b5b54d; // Semi-transparent background
        backdrop-filter: blur(5px); /* Blur effect */
        -webkit-backdrop-filter: blur(40px); /* Safari support */
        .image {
            height: 100%;
            object-fit: contain;
        }
    }
}

.slider-margin {
    .slick-list {
        width: 100%;
    }
}

.vehicle-detail-title {
    font-family: "Onest" !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    color: #0f984f !important;
    padding: 24px 0px 4px 24px;
}

.vehicle-detail-container {
    padding: 0px 4px 16px 24px;
    .user-detail {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 5px 20px;
        .user-detail-name {
            font-weight: 600;
            font-size: 1.5rem;
            font-family: "Onest";
            color: #1a1a1a;
        }
        .user-detail-image {
            padding: 0px 30px;
            margin-right: 10px;
            img {
                border-radius: 50%;
                height: 120px;
                width: 120px;
                object-fit: cover;
            }
        }
    }

    .user-detail-subconatiner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
    }
    .data-labels-container {
        padding: 0px 20px 16px 0px;
    }

    .icon-class {
        color: #8b919d;
        margin-right: 5px;
        width: 13.97px;
        height: 1rem;
    }
    .img-class {
        color: #8b919d;
        margin-right: 5px;
        width: 13.97px;
        height: 14px;
        margin-bottom: 3px;
    }
    .titleClass {
        font-family: "Onest";
        font-weight: 400;
        font-size: 0.9rem;
        color: #8b919d;
    }
    .desc-class {
        font-family: "Onest";
        font-weight: 400;
        font-size: 0.9rem;
        color: #4c4c4c;
    }
}

.linked-account-text {
    font-family: "Onest";
    font-weight: 600;
    font-size: 18px;
    color: #1a1a1a;
}
@media screen and (max-width: 682px) {
    .custom-vertical-dots {
        display: none;
    }
    .slick-dots {
        position: unset !important;
        overflow-x: scroll;
    }
}
