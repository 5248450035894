.custom-text-input {
    position: relative;

    input,
    select {
        color: #ffffff;
        border-radius: 12px; /* Keep the rounded edges */
        border: 1px solid rgba(255, 255, 255, 0.5); /* Lighter border for a subtle look */
        background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
        height: 35px; /* Adjust height for better usability */
        padding-left: 20px; /* Adjust padding for icons */
        padding-right: 20px; /* Adjust padding for icons */
        // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */
        transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */

        &::placeholder {
            color: rgba(255, 255, 255, 0.6); /* Lighter placeholder text */
            font-family: "Onest";
            font-size: 14px; /* Adjust font size */
            font-weight: 400;
        }

        &:focus {
            border-color: #0f984f; /* Change border color on focus */
            // box-shadow: 0px 0px 15px rgba(173, 114, 139, 0.5); /* Highlight shadow on focus */
            outline: none; /* Remove default outline */
            background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
            color: #ffffff;
        }

        /* Autofill styles */
        &:-webkit-autofill {
            background: rgba(255, 255, 255, 0.1) !important;
            color: #ffffff !important;
            -webkit-text-fill-color: #ffffff !important;
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    .input-icon-padding {
        padding-right: 2rem;
    }

    .input-icon {
        position: absolute;
        top: 50%; /* Position from the middle vertically */
        transform: translateY(-50%); /* Adjust for centering */
        right: 12px;
        color: #ffffff; /* Icon color */
    }
}

.app-switch-input {
    &.sm {
        // Write as per need
    }

    &.md {
        // Write as per need

        width: 40px !important;
        height: 18px !important;
        padding: 4px !important;
    }
    &.lg {
        width: 63px !important;
        height: 30px !important;
        padding: 4px !important;
    }
}

.custom-switch {
    width: 76px;
    height: 32px;
    border: 1px solid #dce0e4;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    transition: all 0.4s ease-in-out;

    .switch-label {
        color: #6c757d;
        font-size: 12px;
        font-family: "Onest";
        font-weight: 400;
        margin-left: 5px;
        color: #0b0218;
        transition: color 0.3s ease-in-out;
    }

    .switch-circle {
        width: 22px;
        height: 22px;
        background: #cfeadc;
        border-radius: 50%;
        transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
    }

    &.active {
        justify-content: flex-end;
        border: 1px solid #0f984f;
        .switch-label {
            color: #28a745;
            margin-right: 5px;
            margin-left: 0px;
            font-size: 12px;
            font-family: "Onest";
            font-weight: 400;
        }

        .switch-circle {
            background-color: #28a745;
        }
    }

    &.small {
        width: 76px;
        height: 32px;

        .switch-circle {
            width: 20px;
            height: 20px;
        }

        .switch-label {
            font-size: 12px;
            font-family: "Onest";
            font-weight: 400;
        }
    }

    &.large {
        width: 100px;
        height: 50px;

        .switch-circle {
            width: 30px;
            height: 30px;
        }

        .switch-label {
            font-size: 18px;
        }
    }
}

// .input-icon {
//     position: absolute;
//     top: 50%; /* Center vertically */
//     transform: translateY(-50%);
//     left: 12px; /* Position icon on the left */
//     color: #0f984f; /* Icon color */
// }
