@import "../../assets/scss/theme/theme1/theme.scss";

.custom-btn {
    // box-shadow: 0px 5px 10px 0px #d4d4d4;
    // &.grad-primary {
    //     font-family: "Onest";
    //     background: linear-gradient(185.12deg, #1cea6f -74.08%, #0f984f 73.64%);
    //     box-shadow: 0px 3px 12px #706e6e29;
    //     border-radius: 4px;
    //     // min-width: 345px;
    //     min-height: 40px;
    //     border-color: transparent;
    //     &:hover {
    //         background-color: #064f2f !important; // Dark green color
    //     }
    // }
    &:hover {
        background: linear-gradient(185.12deg, #4bee8d -74.08%, #14c667 73.64%) !important;
    }
    &.grad-primary {
        font-family: "Onest";
        background: linear-gradient(185.12deg, #1cea6f -74.08%, #0f984f 73.64%);
        box-shadow: 0px 3px 12px #706e6e29;
        border-radius: 4px;
        min-height: 40px;
        border: none;

        &:hover {
            background: linear-gradient(185.12deg, #4bee8d -74.08%, #14c667 73.64%) !important;
        }
    }
    &.custom-btn-lg {
        min-width: 200px;
    }
    &.custom-btn-lg-secondary {
        min-width: 200px;
        border: 1px solid $theme-primary-color;
        color: $theme-primary-color;
        background: transparent;
    }
    &.max-width-percent-modified {
        min-width: 100%;
    }
    &.custom-btn-action-green {
        background-color: #368b36;
        border: none;
        font-size: 14px;
        font-weight: 600;
        min-width: 142px;
        min-height: 40px;
        box-shadow: none;
        &:hover {
            background-color: #368b36;
        }
        &.btn-secondary {
            --bs-btn-active-bg: #368b36;
        }
    }
    &.custom-btn-action-red {
        background-color: #f46a6a;
        border: none;
        font-size: 14px;
        font-weight: 600;
        min-width: 142px;
        min-height: 40px;
        box-shadow: none;
        &:hover {
            background-color: #f46a6a;
        }
        &.btn-secondary {
            --bs-btn-active-bg: #f46a6a;
        }
        &:disabled {
            background-color: #f46a6a;
            border-color: #d9d9d9;
            color: #bfbfbf;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
    .btn-icon {
        margin-right: 4px;
        margin-top: 1px;
    }

    &.custom-btn-md {
        min-width: 142px;
        border: 1px solid #0f984f;
        &:hover {
            background: linear-gradient(185.12deg, #4bee8d -74.08%, #14c667 73.64%) !important;
        }
    }
    &.custom-btn-sm {
        min-width: 150px;
        height: 50px;
        font-family: "Onest";
        box-shadow: 0px 3px 12px #706e6e29;
        border-radius: 4px;
        border: 1px solid #0f984f;
        &:hover {
            background: linear-gradient(185.12deg, #4bee8d -74.08%, #14c667 73.64%) !important;
        }
    }
    &.shadow-theme {
        box-shadow: -7px 0px 10px #00000029;
        // min-width: 96px;
    }
}
