@keyframes skeleton-animation {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.skeleton-animation {
    animation: skeleton-animation 1.5s infinite;
}
