.app-auth-wrapper {
    background-color: #fff;
    max-width: 100vw;
    max-height: 100vh;

    .auth-wrap-left-content {
        position: relative;
        height: 100vh;
        width: 100vw;
        .child-container {
            position: absolute !important;
        }
        .auth-right-container {
            width: 35%;
            top: 40%;
            right: 50px;
        }
        .blurred-background {
            padding: 10px;
            background: #b6b5b54d; // Semi-transparent background
            backdrop-filter: blur(10px); // Apply the blur effect
            -webkit-backdrop-filter: blur(40px); // For Safari support
            position: relative; // Ensure positioning works with the absolute content
            border-radius: 24px; // Optional: Add a border radius for better design
        }

        .custom-text {
            font-family: "Onest";
            font-weight: 500;
            font-size: 1.5rem;
            color: #fff;
            text-align: center;
        }
        .custom-label {
            font-family: "Onest";
            font-weight: 500;
            font-size: 1rem;
            color: #fff;
        }
        .custom-auth-right-text {
            font-family: "Onest";
            font-size: 14px;
            font-weight: 600;
        }
        .custom-auth-right-sub-text {
            font-family: "Onest";
            font-size: 14px;
            font-weight: 400;
        }
    }

    a {
        &:focus {
            text-decoration: underline !important;
        }
    }
    .main-image {
        position: fixed;
        width: 100%;
        top: 25%;
        left: 120px;
        .left-container-position {
            position: fixed;
        }
        > h1 {
            position: fixed;
        }
    }
}

.bv-forget-wrap {
    .responsiveness {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.auth-wrap-right-content {
    // css here frt right side
    height: 100%;
    display: grid;
}

@media (max-width: 1440px) {
    .app-auth-wrapper {
        .auth-wrap-left-content {
            .auth-right-container {
                top: 10%;
                right: 30px;
            }
        }

        .main-image {
            top: 25%;
            left: 40px;
        }
    }
}

@media only screen and (max-width: 792px) and (min-width: 768px) {
    .bv-forget-wrap {
        .responsiveness {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 578px) {
    .auth-wrap-right-content {
        margin: 0 3rem;
    }
}

/* Large Tablets (12.9" iPads or similar) */
@media (max-width: 800px) {
    /* Styles for larger tablets or medium desktops */
    .app-auth-wrapper {
        .auth-wrap-left-content {
            .auth-right-container {
                top: 20%;
                right: 20px;
                width: 50%;
            }
        }

        .main-image {
            width: 300px !important;
            top: 30% !important;
            left: 40 !important;
            > h1 {
                font-size: 1rem;
            }
        }
    }
}

/* Large Tablets (12.9" iPads or similar) */
@media (max-width: 768px) {
    /* Styles for larger tablets or medium desktops */
    .app-auth-wrapper {
        .auth-wrap-left-content {
            .auth-right-container {
                top: 30%;
                right: 25%;
                width: 50%;
            }
        }

        .main-image {
            width: 300px !important;
            top: 15% !important;
            left: 30% !important;
            position: absolute !important;
            .left-container-position {
                position: absolute;
            }
            > h1 {
                font-size: 1rem;
            }
        }
    }
}

/* Large Tablets (12.9" iPads or similar) */
@media (max-width: 620px) {
    /* Styles for larger tablets or medium desktops */
    .app-auth-wrapper {
        .auth-wrap-left-content {
            .auth-right-container {
                top: 30%;
                right: 0;
                width: 90%;
                left: 2%;
            }
        }

        .main-image {
            width: 300px !important;
            top: 15% !important;
            left: 10% !important;
            position: absolute !important;
            .left-container-position {
                position: absolute;
            }
            > h1 {
                font-size: 1rem;
            }
        }
    }
}
