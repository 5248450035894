.preview-container {
    width: 100px;
    height: 100px;
    position: relative;
    padding: 5px;

    & .cross-button {
        width: 15px;
        height: 15px;
        background-color: white;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 50px;
    }

    & .edit-button {
        width: 35px;
        height: 35px;
        position: absolute;
        right: -1px;
        bottom: -2px;
    }
    .cursur--pointer {
        cursor: pointer;
    }

    & .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        cursor: pointer;
    }
}
.uploaded-image {
    width: 100%;
    height: 120px;
    object-fit: cover;
}
.selected-preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
