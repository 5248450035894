.btns-main-con {
    width: 75%;
}

.modal-border {
    border-radius: 18px;
}
.custom-title {
    font-family: "Onest";
    font-weight: 600;
    font-size: 20px;
    color: #1a1a1a;
}
.custom-subText {
    font-family: "Onest";
    font-weight: 500;
    font-size: 16px;
    color: #4c4c4c;
}

.custom-close-btn-popup {
    border: none;
    background: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1050;
}
/* Hides the close button */
.modal-header .btn-close {
    display: none !important;
}

@media (max-width: 500px) {
    .btns-main-con {
        width: 100%;
    }
}
