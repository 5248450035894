.otp-input {
    width: 15%;
    height: 15%;
    margin: 5px;
    font-size: 1.01562rem;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    text-transform: uppercase;
    border-color: #ced4da;
    color: #ffffff;
    border-radius: 12px; /* Keep the rounded edges */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Lighter border for a subtle look */
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
    height: 35px; /* Adjust height for better usability */
    padding-left: 20px; /* Adjust padding for icons */
    padding-right: 20px; /* Adjust padding for icons */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}
