.headeing-text {
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 10px;
}
.column-header {
    font-family: "Onest";
    font-size: 0.875rem;
    font-weight: 500;
    color: #1a1a1a;
}

.user {
    display: flex;
    align-items: center;
    width: fit-content;
    .image {
        margin-right: 10px;
        img {
            border-radius: 50%;
            height: 32px;
            width: 32px;
            object-fit: cover;
        }
    }
    div {
        flex: auto;
        font: normal normal medium 15px/18px Roboto;
        color: #000000;
    }
}

.user-detail-container {
    padding: 16px 0px;
    .user-detail {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 5px 0px;
        .user-detail-name {
            font-weight: 600;
            font-size: 1.5rem;
            font-family: "Onest";
            color: #1a1a1a;
        }
        .user-detail-image {
            padding: 0px 15px;
            margin-right: 10px;
            img {
                border-radius: 50%;
                height: 120px;
                width: 120px;
                object-fit: cover;
            }
        }
    }

    .user-detail-subconatiner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
    }
    .data-labels-container {
        padding: 0px 20px 16px 0px;
    }

    .icon-class {
        color: #8b919d;
        margin-right: 5px;
        width: 13.97px;
        height: 1rem;
    }
    .img-class {
        color: #8b919d;
        margin-right: 5px;
        width: 13.97px;
        height: 14px;
        margin-bottom: 3px;
    }
    .titleClass {
        font-family: "Onest";
        font-weight: 400;
        font-size: 0.9rem;
        color: #8b919d;
    }
    .desc-class {
        font-family: "Onest";
        font-weight: 400;
        font-size: 0.9rem;
        color: #4c4c4c;
    }
}
.vehicle-detail-class {
    font-family: "Onest";
    font-size: 1.375rem;
    font-weight: 600;
}

.job-status-common {
    font-family: "Onest";
    font-size: 0.781rem;
    width: 90px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Normal Subscription Status */
.cs-badge-vibrant-orange {
    color: #faa41a;
    background-color: #ffeccc !important;
}

/* Free Subscription Status */
.cs-badge-forest-green {
    font-weight: 500;
    color: #0f984f !important;
    background-color: #e7f5ed !important;
}
.cs-badge-subscription-date {
    font-family: "Onest";
    margin-left: 10px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    border: 1px solid #dce0e4;
    border-radius: 6px;
    color: #4c4c4c !important;
    background: #ffffff;
}

.status-header-search-admin__indicator-separator {
    display: none;
}

//react-date-picker
.btn-height {
    height: 40px;
}
.date-picker-contanier {
    /* Custom DatePicker Styles */
    .date-picker-wrapper {
        position: relative;
        display: inline-block;
        max-width: 121px;
    }
    .calendar-icon {
        font-size: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        pointer-events: none; /* Prevent the icon from blocking input clicks */
        color: #6c757d;
    }
    .line {
        height: 1px;
        background-color: #4c4c4c; /* Line color */
        width: 10px; /* Customize the width of the line */
    }

    .react-datepicker-popper {
        z-index: 999999999999;
    }
    .react-datepicker__input-container input {
        height: 40px;
    }
    .react-datepicker-wrapper {
        display: inline-block;
        width: 100%;
    }

    .react-datepicker__input-container {
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid #ddd;
        border-radius: 10px;
        font-size: 14px;
        font-family: "Onest";
        // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        outline: none;
        transition: border 0.3s ease;
    }

    .react-datepicker__input-container input:focus {
        border-color: #0f984f; /* Primary color */
    }

    .react-datepicker {
        border-radius: 8px;
        border: 1px solid #ddd;
        // box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    }

    .react-datepicker__header {
        background-color: #0f984f; /* Primary color */
        border-bottom: none;
        border-radius: 8px 8px 0 0;
    }

    .react-datepicker__current-month,
    .react-datepicker__day-name {
        color: #fff;
    }
    .react-datepicker__navigation-icon--next,
    .react-datepicker__navigation-icon--previous {
        top: 4px;
    }
    .react-datepicker__day {
        color: #333;
        font-weight: bold;
        font-family: "Onest";
        border-radius: 50%;
        margin: 0.5em;
        width: 2em;
        height: 2em;
        line-height: 1.5rem; /* Adjust line height for days */
    }

    .react-datepicker__day:hover {
        background-color: #f0f0f0;
        border-radius: 50%;
        margin: 0.5em;
        width: 2em;
        height: 2em;
    }

    .react-datepicker__day--selected {
        background-color: #0f984f; /* Primary color */
        color: #fff;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: lighten(#0f984f, 50%);
        color: #fff;
    }

    .react-datepicker__day--today {
        border: 2px solid #0f984f; /* Primary color */
        color: #0f984f;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
        pointer-events: none;
    }

    .react-datepicker__close-icon::after {
        background-color: black;
    }

    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
        background-color: #f0f0f0;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: #0f984f;
        color: #fff;
    }
}
