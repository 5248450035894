@import "../../assets/scss/theme/theme1/functions";
@import "../../assets/scss/theme/theme1/theme.scss";

.account-responsiveness {
    .text-input {
        input {
            color: #4c4c4c !important;
            border: 1px solid #d7d7d7 !important;
            font-family: "Onest";
            font-weight: 400;
            font-size: 1rem;
            height: 36px;
            line-height: 36px !important; /* Set line height to match input height */
            padding: 8px 20px; /* Adjust padding to vertically center text */
            border-radius: 12px;
            padding-top: 6px; /* Adjust this value */
            padding-bottom: 6px; /* Adjust this value */
            &::placeholder {
                font-family: "Onest" !important;
                font-weight: 400 !important;
                font-size: 1rem !important;
                color: #7d7c80 !important;
                line-height: normal !important;
            }
        }
        .input-icon {
            color: #7d7c80 !important; /* Icon color */
        }
    }
    .profile-image {
        width: 130px;
        height: 130px;
        border: 5px solid #2795ae;
    }
    .profile-edit-icon {
        left: 113px;
        bottom: 23px;
    }
}
.account-input {
    font-family: "Onest";
    font-size: 12px !important;
    font-weight: 400 !important;
}

.custom-label {
    font-family: "Onest";
    font-weight: 600;
    font-size: 14px;
    color: #4c4c4c;
}

@media (max-width: 767px) {
    /* For mobile phones: */
    .account-responsiveness * {
        display: flex;
        justify-content: center;
        .profile-edit-icon {
            left: 280px;
        }
        .margin-responsive {
            margin-top: 5px;
            > div {
                width: 100%;
            }
        }
        .text-muted {
            flex-direction: column;
        }
        .custom-btn-lg {
            width: 100%;
        }
        .account-btns-responsiveness {
            margin-top: 0 !important;
            flex-direction: column;
            min-width: 100%;
            .custom-btn-lg-secondary {
                margin-top: 5px;
            }
        }
    }
}

@media (max-width: 540px) {
    /* For mobile phones: */
    .account-responsiveness * {
        .profile-image {
            width: 50%;
            height: 50%;
        }
        .profile-edit-icon {
            left: 27%;
        }
    }
}
