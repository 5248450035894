@import "./icons.scss";

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}

.leaflet-pane {
    z-index: 1;
}
svg > rect:first-child {
    fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
    float: right;
}
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
    padding-left: 15px;
}
.dz-message {
    text-align: center;
    padding: 30px;
}
// kanban board
.react-kanban-column {
    height: 100%;
    min-height: 28px;
    display: inline-block;
    padding: 15px;
    border-radius: 2px;
    background-color: rgb(238, 238, 238);
    margin: 5px;
    vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}
.task-box {
    border: 1px solid $border-color;
}
.react-datepicker-wrapper {
    width: 100% !important;
}
.ReactModal__Overlay {
    z-index: 1001 !important;
}
.external-event {
    &:hover {
        cursor: pointer;
    }
}
a:hover {
    cursor: pointer;
}
.rating-container {
    background-color: transparent !important;
}
.input-group-append {
    z-index: 0;
}
.input-color {
    color: $input-color !important;
}
.sketch-picker {
    position: absolute;
    z-index: 1;
}
.rangeslider__fill {
    background-color: $primary !important;
}

.dropdown-menu-end {
    left: auto !important;
    right: 0 !important;
}

.flatpickr-months .flatpickr-month {
    background: $primary !important;
}
.flatpickr-weekdays {
    background: $primary !important;
}
span.flatpickr-weekday {
    background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary !important;
}
span.flatpickr-weekday {
    color: $white !important;
}
.flatpickr-day.selected {
    background: $primary !important;
}

.bs-select select {
    width: auto !important;
}

//File-manager
.file-manager {
    .table td {
        padding: 0.35rem;
    }
}

//Authenication carousel
.slider_css {
    .slide {
        background: none !important;
    }

    .carousel-status,
    .control-next,
    .control-prev {
        display: none !important;
    }
}
.carousel {
    .control-dots {
        margin: -5px 0px;
    }
    .dot {
        background: #556ee6 !important;
        width: 9px !important;
        height: 9px !important;
    }
}

//Two verification Page
.verification {
    input {
        &:focus {
            border: none;
            outline: none !important;
        }
    }
}

@media (max-width: 768px) {
    .verification {
        input {
            width: 40px !important;
        }
    }
}

@media (max-width: 320px) {
    .verification {
        input {
            width: 30px !important;
            height: 30px !important;
            font-size: 15px !important;
        }
    }
}

//Two verification Page - 2
.verification-2 {
    input {
        &:focus {
            border: none;
            outline: none !important;
        }
    }
}

@media (max-width: 768px) {
    .verification-2 {
        input {
            width: 40px !important;
            font-size: 14px;
        }
    }
}

@media (max-width: 320px) {
    .verification-2 {
        input {
            width: 30px !important;
            height: 35px !important;
            font-size: 15px !important;
        }
    }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    padding: 5px !important;
    font-size: 12px !important;
    margin-right: 2px;
}

@include media-breakpoint-down(sm) {
    .fc-toolbar {
        display: block !important;
        text-align: center;
        .fc-left,
        .fc-right,
        .fc-center {
            float: none;
            display: block;
            clear: both;
            margin: 10px 0;
        }
    }
    .fc {
        .fc-toolbar {
            > * {
                > * {
                    float: none;
                }
            }
        }
    }
    .fc-today-button {
        display: none;
    }
}

//Chat Scrollbar
.ps__rail-y {
    z-index: 999999 !important;
}
.close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
        display: none;
    }
}

//
// Columns
//

.card-columns {
    .card {
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-up(sm) {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;

        .card {
            display: inline-block; // Don't let them vertically span multiple columns
            width: 100%; // Don't let their width change
        }
    }
}

/* drawer */
.react-drawer-drawer {
    z-index: 9999;
    background: #fff;
}
.drawer-main-menu {
    padding-top: 8px;
    padding-bottom: 8px;
}
.drawer-menu a i {
    min-width: 2rem;
    display: inline-block;
    min-width: 1.75rem;
    padding-bottom: 0.125em;
    font-size: 1.25rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #7f8387;
    transition: all 0.4s;
}
.drawer-menu a {
    display: block;
    padding: 0.625rem 1.3rem 0.625rem 1.5rem;
    color: #545a6d;
    position: relative;
    font-size: 13px;
    transition: all 0.4s;
}
.drawer-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
    z-index: 5000 !important;
}
.rangeslider__handle:focus {
    outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
    float: right !important;
}
.search-label {
    float: right;
}
.react-drawer-overlay {
    z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
    .dropdown-menu {
        .dropdown-item {
            a {
                display: block;
            }
        }
    }
}
.rightbar-title .right-bar-toggle {
    background-color: #444c54 !important;
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
    display: block !important;
    color: #eff2f7 !important;
    text-align: center !important;
    border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
    animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
    background: rgba(0, 0, 0, 0.15) !important;
}

// baseCode

p {
    padding: 0;
    margin: 0;
}

// .accordion-header {
//   color: #495057 !important;
//   background-color: transparent !important;

//   .active {
//     color: #4d63cf !important;
//     background-color: #eef1fd !important;
//   }
// }
